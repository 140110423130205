import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { IReverseCharacter } from '../../modules/common/model/graphql-types';
import { faAnglesDown, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { Reverse1999Character } from '../../modules/reverse/common/components/rev-character';
import { RevTagsArray } from '../../modules/reverse/common/components/rev-tags';
import lodash from 'lodash';
import Switch from 'react-switch';

interface IReverseCharacterNodes {
  nodes: IReverseCharacter[];
}

interface IReverseCharacterEntry {
  allCharacters: IReverseCharacterNodes;
}

interface IProps {
  data: IReverseCharacterEntry;
}

const REVTierPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [altRegion, setAltRegion] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '2✦', value: '2' },
          { label: '3✦', value: '3' },
          { label: '4✦', value: '4' },
          { label: '5✦', value: '5' },
          { label: '6✦', value: '6' }
        ]
      },
      {
        key: 'afflatus',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Star',
            tooltip: 'Star',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_star.png"
                width={24}
                alt="Star"
              />
            )
          },
          {
            value: 'Plant',
            tooltip: 'Plant',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_plant.png"
                width={24}
                alt="Plant"
              />
            )
          },
          {
            value: 'Mineral',
            tooltip: 'Mineral',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_mineral.png"
                width={24}
                alt="Mineral"
              />
            )
          },
          {
            value: 'Beast',
            tooltip: 'Beast',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_beast.png"
                width={24}
                alt="Beast"
              />
            )
          },
          {
            value: 'Spirit',
            tooltip: 'Spirit',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_spirit.png"
                width={24}
                alt="Spirit"
              />
            )
          },
          {
            value: 'Intellect',
            tooltip: 'Intellect',
            image: (
              <StaticImage
                src="../../images/reverse/icons/afl_intellect.png"
                width={24}
                alt="Intellect"
              />
            )
          }
        ]
      },
      {
        key: 'damageType',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mental',
            label: 'Mental'
          },
          {
            value: 'Reality',
            label: 'Reality'
          }
        ]
      },
      { key: 'tags', type: 'dropdown', values: RevTagsArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'standard_cn' && altRegion === false) {
      setSortOption('standard_global');
    }

    if (sortOption === 'standard_global' && altRegion === true) {
      setSortOption('standard_cn');
    }

    if (sortOption === 'mane_cn' && altRegion === false) {
      setSortOption('mane_global');
    }

    if (sortOption === 'mane_global' && altRegion === true) {
      setSortOption('mane_cn');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.afflatus && activeFilters.afflatus !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.afflatus.toLowerCase() === activeFilters.afflatus.toLowerCase()
        );
      }
      if (activeFilters.damageType && activeFilters.damageType !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType &&
            emp.damageType.length > 0 &&
            emp.damageType.indexOf(activeFilters.damageType) > -1
        );
      }
      if (activeFilters.tags && activeFilters.tags !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.tags &&
            emp.tags.length > 0 &&
            emp.tags.indexOf(activeFilters.tags) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.slug > b.slug ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.slug < b.slug ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption, altRegion]);

  const sortingOptions = [
    { value: 'overall', label: 'Overall' },
    { value: 'standard_global', label: 'Standard (GLB)' },
    { value: 'standard_cn', label: 'Standard (CN)' },
    { value: 'mane_global', label: 'Mane (GLB)' },
    { value: 'mane_cn', label: 'Mane (CN)' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page reverse-tier'} game="reverse">
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/reverse/categories/category_tier.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Reverse: 1999 Tier List</h1>
          <h2>
            A tier list for Reverse: 1999 that rates all characters based on
            their Global, CN and Overall performance.
          </h2>
          <p>
            Last updated: <strong>18/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Tier List" />
      <Accordion defaultActiveKey="0" className="tier-list-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>About the Tier List</Accordion.Header>
          <Accordion.Body>
            <p>
              Reverse:1999 is a game where{' '}
              <strong>team building and strategy is the main focus</strong>, so
              take the ratings listed here with a grain of salt, as most
              characters can perform well when under the right team and
              circumstances, while conversely, most characters rely on a good
              synergy with their teammates to deliver a good performance.
            </p>
            <p>
              To build this Tier List, we tested all characters both on their
              lower levels and high level, as well as both in optimal and
              non-optimal scenarios. We took into consideration{' '}
              <strong>
                their versatility, performance compared to other characters of
                the same role/niche, and if they grow or fall off as the game
                progresses
              </strong>
              .
            </p>
            <h6>Tier List Options</h6>
            <ul className="no-colors">
              <li>
                <strong>Overall</strong>: How the character performs ignoring
                the patch specific content - the rating showcases the overall
                character strength,
              </li>
              <li>
                <strong>Standard</strong>: How the character performs within
                their role in Event, UTTU, and Limbo,
              </li>
              <li>
                <strong>Raid</strong>: How the character performs within their
                role in the content available in Raids (aka Mane Bulletin).
              </li>
            </ul>
            <h6>Tier List Criteria</h6>
            <ul>
              <li>
                <strong className="dps">Damage Dealer</strong>: Characters whose
                main function is to deal damage.
              </li>
              <li>
                <strong className="sub-dps">Sub Carry</strong>: Characters who
                can both deal damage and help the team in some way.
              </li>
              <li>
                <strong className="support">Support</strong>: Arcanists who you
                bring to the team for their utility or support aspects, be it
                buffs, debuffs, control, and so on.
              </li>
              <li>
                <strong className="sustain">Survival</strong>: Healers, Tanks,
                and the ones with tools who help keep the team alive.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Ratings explained</Accordion.Header>
          <Accordion.Body>
            <ul className="no-margin">
              <li>
                <strong>S+</strong> - characters that are the best at their role
                for this content.
              </li>
              <li>
                <strong>S</strong> - powerful characters that are slightly
                inferior to the S+ ones, but there are some things holding them
                back.
              </li>
              <li>
                <strong>A+</strong> - strong character that perform well that
                still have place in the meta, but require a bit more work to
                shine.
              </li>
              <li>
                <strong>A</strong> - decent characters that are commonly used to
                replace the meta ones when you don't have them or they have some
                niche uses.
              </li>
              <li>
                <strong>B</strong> - average characters that have big flaws and
                only work as some niche replacements when you don't have
                anything else.
              </li>
              <li>
                <strong>C</strong> - characters with very niche kits or that
                struggle due to low stats.
              </li>
              <li>
                <strong>D</strong> - characters with niche kits that are
                difficult to justify, as well as having low stats.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Changelog <span className="tag updated">Updated!</span>
          </Accordion.Header>
          <Accordion.Body>
            <h6>18.09.2024</h6>
            <p>New additions (1.9):</p>
            <ul>
              <li>
                <strong>Lucy</strong> [S+/S+/S+]. One half of the top Damage
                Dealers in the game. Lucy's Afflatus, [Pragmatist] buff and
                varied options for damage allows her to fit into pretty much any
                team and piece of content in the game.
              </li>
              <li>
                <strong>Kakania</strong> [S+/S/S+]. The first proper Tank in the
                game (can redirect damage taken by allies) and at the same time
                an extremely powerful Support with [Solace] and Damage Dealer
                with her Insights.
              </li>
              <li>
                <strong>Semmelweis</strong> [A+/A+/A+]. The game's first foray
                into directly benefitting from HP Drain, Semmelweis can provde a
                LOT of DMG Bonus and Crit Rate to her allies, assuming they can
                survive.
              </li>
              <li>
                <strong>Lorelei</strong> [S/S/S]. The Sonetto of Year 2, Lorelei
                provides passive DMG Dealt buffs, Crit DMG and also enhances the
                team's survivability with various defensive (de)buffs.
              </li>
            </ul>
            <p>Standard changes (1.9):</p>
            <ul>
              <li>
                <strong>Jiu Niangzi</strong> [From S+ to S]. The arrival of Lucy
                and the new Tuning for Windsong places those two well above Jiu
                Niangzi. She remains the Queen of FUA and has incredibly
                consistent damage, but she now falls closer to characters like
                Marcus and Spathodea.
              </li>
              <li>
                <strong>Melania</strong> [From S to A+]. Unfortunately, due to
                Mental Teams not really getting anything in recent patches, and
                the damage ceiling slowly rising with Windsong and Lucy, Melania
                struggles to hold on as Moxie and Ult spam are the things that
                keep her relevant.
              </li>
              <li>
                <strong>A Knight</strong> [From A+ to A]. Used to be the premier
                Primal Afflatus Damage Dealer (and the only relevant one), he is
                pushed out of his role as a spammy Mass Carry and general use
                Damage Dealer by Lucy due to his perfectly average gameplay,
                something that is considered mediocre in the realm after 1.9.
              </li>
              <li>
                <strong>Satsuki</strong> [From A to B]. Still a relevant Carry
                for Pertrify teams however, due to Kakania's release, Crowd
                Control teams veer more so into the area of things like Druvis /
                An-An Lee / Kakania.
              </li>
              <li>
                <strong>An-An Lee</strong> [From S to A+]. Simply just doesn't
                do as much as newer supports and will generally get beat out by
                characters like Lorelei and 6 who have more offensive support
                options as well as defensive ones.
              </li>
              <li>
                <strong>Voyager</strong> [From A+ to A]. An amazing (albeit
                ap-negative) defensive Support, Voyager nowadays is mostly
                relegated to hyper Crowd Control comps like Druvis / An-An Lee /
                Voyager / Kakania.
              </li>
              <li>
                <strong>Pickles</strong> [From A+ to A]. Kind of similar in
                reasoning to An-An Lee, all Pickles really has going for him is
                his ap-positive playstyle and the ability to full-dispel on a
                basic Incantation (under the right circumstances).
              </li>
              <li>
                <strong>Sonetto</strong> [From A to B]. Overshadowed by Lorelei
                due to both being free units. Sonetto remains useful for her
                high personal damage and somewhat consistent access to [Disarm],
                but not much beyond that.
              </li>
              <li>
                <strong>Erick</strong> [From B to C]. Due to the amount of Crit
                Support we've had over the months Erick really has no reason to
                be used beyond maybe low-star content or aid unit in Roguelite.
              </li>
            </ul>
            <p>Raid changes (1.9):</p>
            <ul>
              <li>
                <strong>Jiu Niangzi</strong> [From S+ to S]. Great in Opera and
                Nightmare, unusable in Gorgon due to incompatible kit.
              </li>
              <li>
                <strong>Melania</strong> [From S to A+]. Great in Opera, okay in
                Nightmare and unusable in Gorgon due to incompatible kit.
              </li>
              <li>
                <strong>A Knight</strong> [From A to A+]. Does great in
                Nightmare due to his spammy Mass attacks.
              </li>
              <li>
                <strong>37</strong> [From A+ to S]. Amazing in Gorgon and
                Nightmare, passable in Opera if used in combination with
                Windsong, Jiu Niangzi or Lucy.
              </li>
              <li>
                <strong>Shamane</strong> [From A+ to A]. Doesn't really do all
                that great in any of the raids but can do decent in Nightmare.
              </li>
              <li>
                <strong>Medicine Pocket</strong> [From S to A+]. Great in Opera
                and decent in Nightmare but unusable in Gorgon due to
                incompatible kit.
              </li>
            </ul>
            <h6>02.09.2024</h6>
            <p>
              We have fully reworked the tier list. Read more on our{' '}
              <a
                href="https://blog.prydwen.gg/2024/09/02/reverse-1999-complete-tier-list-overhaul/"
                target="_blank"
                rel="noreferrer"
              >
                blog
              </a>
              .
            </p>
            <h6>13.08.2024</h6>
            <p>New additions:</p>
            <ul>
              <li>
                <strong>Vila</strong> [S+/S+/S+]. For now the only "pure healer"
                capable of rivaling the fabled Tooth Fairy due to her Crit buffs
                and Moxie support.
              </li>
              <li>
                <strong>Windsong</strong> [S+/S+/S+]. She is without a doubt the
                best single-target burst Carry in the game, easier to play in
                Raid but still extremely powerful in standard content.
              </li>
              <li>
                <strong>Avgust</strong> [A/A/A+]. A cute fellow with a very
                unique kit, doesn't really do that well in 3-man content due to
                his kit but excels in Raids as a secondary Support.
              </li>
            </ul>
            <p>Adjustments Standard:</p>
            <ul>
              <li>
                <strong>37</strong> [S → A+]. Just doesn't do much as a Carry as
                newer and better options. Can be used as a Support for the top
                damage dealers though.
              </li>
              <li>
                <strong>Jessica</strong> [S → A+]. Marcus and Vila really hurt
                the viability of Poison teams not because Jessica is bad but
                because she just can't reach the heights they do together.
              </li>
              <li>
                <strong>Centurion</strong> [Justification]. Despite falling hard
                off the cliff of her initial debut, Centurion remains very
                AP-friendly and consistent in Standard content.
              </li>
              <li>
                <strong>Druvis III</strong> [Justification]. The Petrify niche,
                while mediocre in anything Raid related, is known for running
                its course in especially Limbo and also some UTTUs, with Druvis
                III at its forefront.
              </li>
              <li>
                <strong>Marcus</strong> [A+ → S]. Now with Vila's Crit buffs,
                afflatus, and a few more support options, Marcus slowly climbs
                the ladder to the stardom she deserves as a very powerful and
                high potential Carry.
              </li>
              <li>
                <strong>Regulus</strong> [A+ → A]. Though still wildly useful
                for the Dodge effect on her Ultimate, Regulus just simply
                doesn't do as much as new damage dealers, and her playstyle
                remains clunky.
              </li>
              <li>
                <strong>Eternity</strong> [A → B]. Her damage type doesn't
                really get supported this patch and there isn't really a need
                for a tanky, self-sustaining damage dealer. In case its needed,
                Centurion or A Knight do a fine job.
              </li>
              <li>
                <strong>Satsuki</strong> [Justification]. A core member of the
                Petrify team.
              </li>
              <li>
                <strong>Kanjira</strong> [B → C]. Doesn't do enough to really
                find herself in the damage dealer category and her rotation is
                too strict to provide good timing windows for support.
              </li>
              <li>
                <strong>Leilani</strong> [B → C]. Mostly used in Raids like Gold
                in the Cave to cheat out Ultimates with Darley Clatter.
              </li>
              <li>
                <strong>An-An Lee</strong> [Justification]. While lacking in
                terms of Raid viability. AAL sees potential in Marcus and
                Windsong teams as a standard Support but also fits into the
                Petrify team to bring [Daze] and additional defensive buffs.
              </li>
              <li>
                <strong>Pickles</strong> [Justification]. Still works due to his
                AP-friendly nature and decent damage output (+ access to full
                dispel) but is bound to fall soon.
              </li>
              <li>
                <strong>Shamane</strong> [Justification]. Works well until the
                newer universal Supports (cough cough Mercuria, Kakania) find
                their way to global and is bound to fall soon.
              </li>
              <li>
                <strong>Horropedia</strong> [A → B]. If you want a dispeller
                then X or Charlie are better options as Horropedia splits his
                power budget between dispel and damage and also relies on higher
                rank cards.
              </li>
              <li>
                <strong>Darley Clatter</strong> [B → C]. See Leilani.
              </li>
              <li>
                <strong>Erick</strong> [B → C]. Just doesn't see much use
                specifically with the amount of good Crit supports we have.
              </li>
              <li>
                <strong>Oliver Fog</strong> [B → C]. Defensive Supports aren't
                really liked in 3-man content which is usually quicker and
                relies on high immediate damage output.
              </li>
              <li>
                <strong>Зима</strong> [B → C]. Partially relies on Ultimate to
                do a decent job and just doesn't have much in the way of good
                buffing.
              </li>
              <li>
                <strong>Ezra Theodore</strong> [S → A+]. Takes too much time to
                set up and just doesn't do that well as a solo-sustain.
              </li>
              <li>
                <strong>Medicine Pocket</strong> [A+ → S]. A defensive healer
                like Yenisei, sure, but has access to [Daze] and a decent
                offensive debuff on the side.
              </li>
              <li>
                <strong>Dikke</strong> [A → B]. Works well in certain raids but
                her healing output (her main job) is just rather risky and
                better reserved for later with teams like Semmelweis.
              </li>
            </ul>
            <p>Adjustments Raid:</p>
            <ul>
              <li>
                <strong>Kaalaa Baunaa</strong> [A+ → S]. Great in both Gold and
                Opera, high single target damage, amazing duo with Vila, our
                favorite stargazer eats good this patch.
              </li>
              <li>
                <strong>Centurion</strong> [A+ → A]. Really has no justification
                to be put any higher than A this time around as she doesn't
                really function well in any of the raids.
              </li>
              <li>
                <strong>Blonney</strong> [A → B]. Despite getting a good
                psychube this time around, Blonney simply struggles with doing
                damage with her narrow timings and required set-up.
              </li>
              <li>
                <strong>Charlie</strong> [A → B]. Not much to write about, just
                isn't as good at Raids as she is standard content, and none of
                the Raids this patch really favor her.
              </li>
              <li>
                <strong>Lilya</strong> [A → B]. See Charlie.
              </li>
              <li>
                <strong>Leilani</strong> [Justification]. Does well in Ultimate
                teams with Darley Clatter and that happens to be good in some of
                the Raids this patch.
              </li>
              <li>
                <strong>Eagle</strong> [B → C]. Struggles to survive and doesn't
                really do much in any of the Raids for this patch.
              </li>
              <li>
                <strong>An-An Lee</strong> [S → A+]. Her AP-positive playstyle
                isn't as needed in Raid and her [Daze] often doesn't work either
                but she is decent in a Raid this time around.
              </li>
              <li>
                <strong>Pickles</strong> [A+ → A]. Doesn't really do anything in
                the Raids in the current patch and his AP-positive playstyle
                isn't needed.
              </li>
              <li>
                <strong>Baby Blue</strong> [A → B]. No longer the only good
                Mental Support and requires too much set-up for mediocre payoff
                (also [Nightmare] is garbage).
              </li>
              <li>
                <strong>Horropedia</strong> [A → B]. See Standard Adjustments.
              </li>
              <li>
                <strong>Twins Sleep</strong> [A → B]. While very good, they do
                require some set-up and their [Nightmare] has no use in Raids.
              </li>
              <li>
                <strong>Darley Clatter</strong> [Justification]. See Leilani.
              </li>
              <li>
                <strong>Diggers</strong> [B → C]. Doesn't actually do much in
                Raids due to his power budget being partially allocated to
                defensive debuffs and [Nightmare] of all things (begging, on my
                knees, Bluepoch make it viable).
              </li>
              <li>
                <strong>Ulu</strong> [B → C]. Doesn't actually do anything in
                the Raids this time around. Her only saving grace is Isolde
                ramping teams which aren't that good in the Raids of this patch.
              </li>
              <li>
                <strong>Oliver Fog</strong> [B → C]. Entirely defensive Support
                which is falling out of favor.
              </li>
              <li>
                <strong>Medicine Pocket</strong> [S → A+]. Jack of all trades
                healer that neither specializes in full-on defense like Jenisei
                or offense like Tooth Fairy / Vila and just falls a bit in the
                awkward middle.
              </li>
              <li>
                <strong>Sotheby</strong> [A+ → A]. Homegirl is tragic until more
                Poison Support shows up tbh, sorry (she doesn't function in any
                of the current Raids).
              </li>
              <li>
                <strong>Tennant</strong> [A+ → A]. Excels in Marsh Creation and
                Robot as a Carry but can't replicate that in other Raids.
              </li>
              <li>
                <strong>Dikke</strong> [A → B]. See Tennant.
              </li>
            </ul>
            <h6>06.07.2024</h6>
            <p>
              The CN Tier lists have been update for 2.0 patch and the Global
              tier lists have been updated for 1.7 patch. List of changes:
            </p>
            <ul>
              <li>
                <strong>General</strong>:
              </li>
              <ul>
                <li>
                  <strong>J (Joe)</strong>: Placed [A+/A+/S]
                </li>
                <li>
                  <strong>Pioneer</strong>: Remains unrated as his kit is
                  generally confusing to place in the current meta where
                  "moving" units isn't really something that sees use besides
                  maybe Avgust.
                </li>
              </ul>
              <li>
                <strong>Global 1.7</strong>:
              </li>
              <ul>
                <li>
                  <strong>Isolde</strong>: Placed [S+/S+/S+] for pretty obvious
                  reasons. Overall best offense-oriented Support in Global with
                  only 6 as actual competition currently.
                </li>
                <li>
                  <strong>Marcus</strong>: Placed [S/A+/S] as she severely lacks
                  teambuilding options in 3-man teams (Global) but excels at
                  damage output when she is catered to (which she is this
                  patch).
                </li>
                <li>
                  <strong>Bkornblume</strong>: Adjustment Standard [S to A+] due
                  to there generally being better options for Reality Support
                  now in Getian and Isolde.
                </li>
                <li>
                  <strong>Centurion</strong>: Adjustment Standard [S to A+] the
                  prior update and this one is where Centurion generally starts
                  to fall off, she also doesn't match any of the afflatuses
                  present in Limbo.
                </li>
                <li>
                  <strong>Ezra Theodore</strong>: Justification Standard [S],
                  despite being known as a "raid only" unit, this the Limbos
                  running throughout 1.7 benefit him greatly.
                </li>
                <li>
                  <strong>Leilani</strong>: Adjustment Standard [A to B] as she
                  is generally used in Raids to cheat out Ultimates but doesn't
                  see much use elsewhere besides low-rarity teams.
                </li>
                <li>
                  <strong>Lilya</strong>: Adjustment Standard [A+ to A] as she
                  is pushed out by Isolde and Jiu in terms of 37 Support and
                  with that she loses her niche in consistent FUAs.
                </li>
                <li>
                  <strong>Satsuki</strong>: Justification Standard [A], as she
                  is one of the core members of Petrify teams which is not only
                  amazing in Standard content but supported by the afflatuses in
                  Limbo this patch.
                </li>
                <li>
                  <strong>Shamane</strong>: Adjustment Standard [S to A+] as
                  damage-type-specific Supports become more relevant Shamane, as
                  a universal debuffer, automatically starts to fall off.
                </li>
                <li>
                  <strong>Regulus</strong>: Adjusted Raid [A+ to A] as while her
                  Dodge is useful in the late stages of Raid she doesn't perform
                  as well as other characters in most of the available Raids.
                </li>
                <li>
                  <strong>Blonney</strong>: Adjustment Raid [B to A] as I think
                  she generally functions pretty well in Raid content as a
                  subsitute for Lilya for example (does better than people
                  think).
                </li>
                <li>
                  <strong>Darley Clatter</strong>: Adjustment Raid [B to A] as
                  he is currently utilized in combination with Leilani to cheat
                  out Ultimates rapidly, which is especially usable in 4-man
                  content.
                </li>
                <li>
                  <strong>Erick</strong>: Adjustment Raid [B to C] as we've
                  honestly never seen her being used and there are way better
                  supports for Crit even in her own rarity.
                </li>
                <li>
                  <strong>Leilani</strong>: Adjustment Raid [C to B] as she does
                  really well the Ultimate-cheating teams.
                </li>
                <li>
                  <strong>Lilya</strong>: Adjustment Raid [A+ to A] as she
                  doesn't really fit the bill on any of the available raids.
                </li>
                <li>
                  <strong>Ms. NewBabel</strong>: Adjustment Raid [B to A] as she
                  functions well in the Nightmare raid.
                </li>
                <li>
                  <strong>Pickles</strong>: Adjustment Raid [S to A+] as Getian
                  does his buffing job better and an AP-positive playstyle isn't
                  as enjoyed in 4-man content.
                </li>
                <li>
                  <strong>Shamane</strong>: Adjustment Raid [S to A+] for the
                  same reason as his adjustment on Standard.
                </li>
                <li>
                  <strong>Spathodea</strong>: Adjustment Raid [S+ to S] as she
                  doesn't particularly excel at all 3 raids but remains an
                  excellent boss-killer especially with Isolde now in the mix.
                </li>
                <li>
                  <strong>Sonetto</strong>: Adjustment Raid [A+ to A] as she
                  doesn't do as well as others anymore but her [Disarm] is
                  useful and so are her buffs.
                </li>
                <li>
                  <strong>Tennant</strong>: Adjustment Raid [A to A+] as she
                  performs surprisingly well as a jack-of-all-trades in
                  Hypothesis.
                </li>
                <li>
                  <strong>Twins Sleep</strong>: Adjustment Raid [B to A] as one
                  of the most slept-on Supports especially in content where AP
                  doesn't fully matter.
                </li>
                <li>
                  <strong>Ulu</strong>: Justification Raid [B] does well in one
                  of the raids and CAN function in weird Isolde ramp teams if
                  that's your style (and if it is, literally what are you
                  doing).
                </li>
              </ul>
              <li>
                <strong>CN 2.0</strong>:
              </li>
              <ul>
                <li>
                  Windsong: Adjustment Standard [S to S+], due to the new tuning
                  offered in 1.9 she is quite straight up the best Mental Carry
                  in the game and able to match Lucy even on Standard.
                </li>
              </ul>
            </ul>
            <h6>14.06.2024</h6>
            <p>The CN Tier lists have been updated:</p>
            <ul>
              <li>
                <strong>Kakania</strong> - Added! S+ on Mane's CN, A+ on
                Standard CN, S Overall. Kakania does well in all raids, thanks
                to her damage being mostly Genesis and the fact that she amps up
                both the survivability and burst capability of any team. She
                falls off a bit on standard 3P content, where fights are short
                and her tools aren't as effective, but this content becomes less
                relevant by the day, earning her an Overall rating of S.
              </li>
              <li>
                <strong>Blonney</strong> - Rating up to B on Standard CN and A
                on Raids CN. "For Rehabilitation" as well as newer
                supports/sustain characters greatly help amp up her potential
                compared to what she had available during her release.
              </li>
              <li>
                <strong>Lilya</strong> - From B to A on Standard CN. Same
                reasons as Blonney.
              </li>
              <li>
                <strong>Twins Sleep</strong> - From A to B in Overall and Mane's
                CN. Despite still being an overall useful and cheap pick, their
                low stats make them struggle to stay alive (and consequently
                useful) in newer content. Besides, there are a lot of newer
                picks that are hard to compete with.
              </li>
              <li>
                <strong>Ms. Newbabel</strong> - From A to B in all ratings. Can
                be very good in some content, but is overall very niche and
                relatively difficult to play. Used to be the closest thing to a
                "tank" in the game, but nowadays, we have better picks on the
                defensive area.
              </li>
              <li>
                <strong>Tennant</strong> - All ratings adjusted to A. Shielders
                have become more relevant in recent patches, she has more and
                better psychube options, more synergy options and can grant
                shields passively and actively. As well as dealing decent DMG
                and applying a good debuff to amp up Reality characters' DMG.
              </li>
              <li>
                <strong>Avgust</strong> - From A+ to A in all ratings. Avgust is
                still a useful and notable unit, but not as impactful as other
                picks in A+ tier.
              </li>
            </ul>
            <p>
              Also, a lot of the tags in the Tier List were updated. They aim to
              reflect core aspects of the character in order to help with Team
              Building and synergies. If you want to check if a character is
              Reality or Mental type, you can hover over their picture or use
              the filter function in the Tier List.
            </p>
            <h6>07.06.2024</h6>
            <p>Tier list has been updated to 1.9 patch for CN:</p>
            <ul>
              <li>
                <strong>Lucy</strong> - [S+] Given her focus on AoE DMG and the
                FUA triggers off of kills Lucy absolutely dominates standard
                content on levels we haven't seen before.
              </li>
              <li>
                <strong>Windsong</strong> - [S+ to S] Due to her playstyle being
                based on card management she doesn't do well in content with
                less AP and less card space, still hits very hard but just tough
                to play.
              </li>
              <li>
                <strong>Jiu Niangzi</strong> - [S+ to S] Due to her high
                consistent damage (and BiS psychube) she wants to exploit long
                fights which aren't common in standard content.
              </li>
              <li>
                <strong>Spathodea</strong> - [S to A+] Doesn't do as much damage
                in content where she has a hard time stacking buffs and dealing
                single-target damage.
              </li>
              <li>
                <strong>A Knight</strong> [Justification] As one of the most
                AP-Positive Carry units he feels really good to play in standard
                content unlike most Carries nowadays.
              </li>
              <li>
                <strong>Druvis III</strong> - [Justification] In Standard
                content the Petrify team is one of the most notorious ez stage
                sweeper teams.
              </li>
              <li>
                <strong>37</strong> - [Justification] At P0 she doesn't function
                as a Carry in the slightest and her Support capabilities are so
                generally abysmal that we just don't see her be good there.
              </li>
              <li>
                <strong>Isolde</strong> - [S+] Basically the best debuffer, does
                her thing without having to move, throws out debuffs, buffs and
                FUAs, just does way too much.
              </li>
              <li>
                <strong>An-An Lee</strong> - [Justification] The counterpart
                buffer in the Standard TL, super AP-friendly, guaranteed
                offensive and defensive buffs and own card upgrading.
              </li>
              <li>
                <strong>6</strong>- [Justification] While great 6 is a bit
                AP-negative which doesn't mesh with standard content and his
                buffs are a 5050 between offense and defense (he's also more
                defense oriented and has an ST Ultimate).
              </li>
              <li>
                <strong>Getian</strong> - [S to A+] Struggles in 3-man content
                at P0 due to the nature of his kit charging his Ultimate more
                slowly there.
              </li>
              <li>
                <strong>Shamane</strong> - [S to A+] Universal debuffers are
                less preferable now that type-specific comps are becoming more
                important.
              </li>
              <li>
                <strong>Pickles</strong> - [S to A+] Entirely carried by his
                AP-friendly playstyle and strong niche in dispelling buffs from
                enemies.
              </li>
              <li>
                <strong>Lorelei</strong> - [Placement] More or less a roguelike
                centered character, really enjoys content where more cards are
                featured (which is anything but standard content).
              </li>
              <li>
                <strong>Horropedia and X</strong> - [A to B] Dispellers aren't
                quite needed right now and while X' [Silence] spam is great it's
                situational to him being able to dispel buffs.
              </li>
              <li>
                <strong>Tooth Fairy</strong> - [S+] Everyone knows why she is
                here.
              </li>
              <li>
                <strong>Vila</strong> - [S] Down one tier for Standard,
                reasoning being that she doesn't function as well with a limited
                amount of teammates due to relying on Mental Teams to hit that
                [Rusalka] state asap.
              </li>
              <li>
                <strong>Kakania</strong> - Not placed yet due to testing.
              </li>
              <li>
                <strong>Medicine Pocket</strong> - [Justification] A part of
                their 'healing output' comes from the consistent [Sturdiness]
                severely impacting incoming damage.
              </li>
            </ul>
            <h6>30.05.2024</h6>
            <p>Tier list has been updated to 1.6 patch for Global.</p>
            <ul>
              <li>
                The Tier List takes into consideration the Limbo seasons
                favoring Mineral, Beast and Star. As well as the regular patch
                content and Mane's Bulletin.{' '}
              </li>
              <li>
                <strong>Jiu Niangzi</strong> is the highlight of this patch, no
                surprises or explanations needed for her position here.
              </li>
              <li>
                <strong>Spathodea</strong> does well both on the new raid boss
                and is still a top pick on the other two in this patch. Hence
                why she's S+ on raids despite her DMG not being on Jiu's level.
              </li>
              <li>
                <strong>Getian</strong> isn't the best support for 3-People
                content unless you have him at P2, but for 4-People content,
                he's one of the best partners for any Reality carry, and this
                patch favors Reality DMG dealers. He's good on all three raids.
              </li>
              <li>
                <strong>Yenisei</strong> is basically a 6-star healer in a
                5-star disguise. Her shield + Immunity combo are game-changers
                for some of the more challenging content such as Mane's, but she
                lacks a bit of the utility and AP-positive gameplay that Tooth
                Fairy brings, and thus, Yenisei remains at S.
              </li>
              <li>
                <strong>37</strong> is a bit higher here due to the "Jiu
                National" combo. She basically got buffed by Jiu's existence.
              </li>
              <li>
                <strong>Desert Flannel</strong> does well with Getian and
                Spathodea, shining more on 4-People content, for the same
                reasons they do.
              </li>
            </ul>
            <h6>18.04.2024</h6>
            <ul>
              <li>
                <strong>Jiu Niangzi</strong> from S &gt; S+ in Overall tier
                list. Yeah, that was fast but we did say that we wouldn't put
                restrictions on high tiers and Jiu still matches Windsong in
                realistic simulations and also does better than Lucy in single
                target DMG, so we admit dropping her down a tier was a bit too
                sudden. Thanks for all the feedback!
              </li>
              <ul>
                <li>
                  So for now, Jiu Niangzi, Lucy and Windsong will all sit in S+
                  tier for Overall and we will retest the different areas where
                  they shine and reflect that in the Raid/Standard tier lists
                  once they're updated to the 1.9 patch (which will happen
                  soon!).
                </li>
              </ul>
              <li>
                <strong>Pickles</strong> from S &gt; A+. He mostly relies on his
                Dispel niche and AP-Positive playstyle at the moment and new
                Supports just do more nowadays.
              </li>
              <li>
                <strong>Ezra</strong> from A+ &gt; S. With Vila being added into
                S+ we feel like having Ezra back into S as an offensive option
                next to Yenisei's defensive one is appropriate.
              </li>
            </ul>
            <h6>17.04.2024</h6>
            <p>
              Added 1.9 characters to the Overall tier list and made several
              changes to other ratings. Summary:
            </p>
            <ul>
              <li>
                <strong>Lucy</strong> is hard to play but hits like an absolute
                goddamn truck. Her damage potential is just really high, she is
                really easy to build into Crit teams due to a ridiculous 882
                Crit Tech (29% base Crit Rate, highest in the game) and her
                psychube giving Crit also. Her only flaw presently from testing
                is that she's harder to play than most other Damage Dealers.
              </li>
              <li>
                <strong>Jiu Niangzi</strong> from S+ to S. Lucy has completely
                and utterly outclassed Jiu Niangzi in pretty much every way.
                Unhinged damage, a ridiculous 800+ Crit Tech and unfortunately
                Reality DMG which makes her contend with Jiu for a spot. An
                argument in Jiu's favor is that she is incredibly simple
                compared to Lucy, but we don't really factor difficulty of play
                into our ratings unless its team-based such as with Kaalaa
                Baunaa in a 3-man team.
              </li>
              <li>
                <strong>Jessica</strong> from S to A+. The Poison archetype has
                been left pretty much in the dust and she just doesn't deal as
                much damage as other Carries anymore.
              </li>
              <li>
                <strong>Kaalaa Baunaa</strong> from S to A+. Still deals great
                damage but just gets outclassed by newer Carries and is nowadays
                functionally relegated to Raids most of the time.
              </li>
              <li>
                <strong>
                  A Knight, Centurion, Charlie, Druvis III, Regulus
                </strong>{' '}
                from A+ to A. Most of these units just struggle to keep up
                nowadays, they have their uses especially in Raids and still
                function fine in standard content but they're not big hitters
                like Marcus or Melania.
              </li>
              <li>
                <strong>Blonney, Eternity, Lilya, Sweetheart</strong> from A to
                B. Lowered only by virtue of having the A+ lowered to A.
              </li>
              <li>
                <strong>
                  Eagle, Kanjira, Leilani, Matilda, Pavia, Satsuki
                </strong>{' '}
                from B to C. Lowered only by the virtue of having A lowered to
                B.
              </li>
              <li>
                <strong>Bunny Bunny, Mondlicht, Rabies</strong> from C to D.
                Lowered only by virtue of having B lowered to C.
              </li>
              <li>
                <strong>Semmelweis</strong> is quite strong and has a really
                nice damage amp however due to her health drain she is quite
                risky to play without a shielder which ends up breeding a new
                kind of niche that could see shield based sustain units increase
                in play rate.
              </li>
              <li>
                <strong>Click, Ulu, Darley Clatter</strong> from B to C to split
                up the crowded B-tier and show who are more generally useful in
                that group. These characters occupy a VERY specific niche that
                rarely comes up.
              </li>
              <li>
                <strong>
                  Bette, Door, Mesmer Jr, Ms Radio, Nick Bottom, ONiON, Sputnik,
                  TTT
                </strong>{' '}
                from C to D by virtue of others moving from B to C.
              </li>
              <li>
                <strong>Vila</strong> from S to S+. While Tooth Fairy is still
                the premiere Healer who you use in Crit-based teams, Vila
                focuses more on generic DMG increase and better healing. So they
                both can be best in slot depending on the team you want to run.
              </li>
              <li>
                <strong>Dikke, La Source, Tennant</strong> from A to B to split
                up the crowded A-tier. Dikke has clunkier healing, La Source has
                unfortunately low stats and rarity and is thus low prio and with
                the amount of amazing Reality Supports in the game now Tennant
                is hard to justify using.
              </li>
              <li>
                <strong>
                  aliEn T, APPle, Poltergeis, Ms Moissan, The Fool
                </strong>{' '}
                from C to D to split up the C tier. These characters really
                don't provide that much anymore at this stage of the game.
              </li>
            </ul>
            <h6>02.05.2024</h6>
            <p>Overall tier list has been updated. List of changes:</p>
            <ul>
              <li>
                <strong>Blonney</strong> from B to A. A surprising resurgence in
                usage and a great user of the new "For Rehabilitation" psychube.
              </li>
              <li>
                <strong>Shamane</strong> from S to A+. Now that type-specific
                damage is becoming more and more important, universal debuffers
                really struggle.
              </li>
              <li>
                <strong>Diggers</strong> from A to B. Has some synergy in CC
                teams but there's honestly way better options and [Nightmare]
                still sucks.
              </li>
              <li>
                <strong>Medicine Pocket</strong> from S to A+. Still a great
                healer but can't keep up with Yenisei defensively and can't keep
                up with Vila offensively.
              </li>
              <li>
                <strong>Ezra</strong> from S to A+. Still a lot of debate about
                whether he's good and while he is amazing we felt this would be
                a better place.
              </li>
              <li>
                <strong>Balloon Party</strong> from A+ to A. Fine healer but
                Lost HP healing is kind of niche and she doesn't stack up to the
                others in A+.
              </li>
              <li>
                <strong>Sotheby</strong> from A+ to A. Fine enough healer but
                really wants to be in a team with Jessica and we can't consider
                that "meta".
              </li>
              <li>
                <strong>Cristallo, John Titor and The Fool</strong> from B to C.
                Just don't do enough compared to Tennant and La Source.
              </li>
            </ul>
            <p>
              The CN tier list has been updated from 1.7 to 1.8. List of
              changes:
            </p>
            <ul>
              <li>
                Added <strong>Avgust</strong>. He is a really solid Support that
                properly balances Offensive and Defensive buffs in a way that
                makes both work at a level where you'd actually want to use him.
              </li>
              <li>
                Added <strong>Vila</strong>. The new Healer release and probably
                the closest thing we've gotten to the second coming of Tooth
                Fairy; she's only held back by her overreliance on [Rusalka] and
                her mediocre performance outside of Mental teams.
              </li>
              <li>
                Added <strong>Windsong</strong>. Probably the most broken
                addition to date even considering Jiu Niangzi. Windsong has the
                mathematically highest damage ceiling in the game bar none, and
                even when realistically simulated, her output is higher than
                that of Jiu Niangzi.
              </li>
            </ul>
            <h6>26.04.2024</h6>
            <p>Added Windsong to the tier list.</p>
            <h6>11.04.2024</h6>
            <p>Overall tier list has been updated. List of changes:</p>
            <ul>
              <li>
                <strong>37</strong> from S to A+ in Overall. Just average damage
                at P0 and honestly can't justify a better spot.
              </li>
              <li>
                <strong>Marcus</strong> from A+ to S in Overall. Vila's release
                will make her better - no matter what and she deals really good
                damage at P0.
              </li>
              <li>
                <strong>Charlie</strong> from A to A+ in Overall. She's still
                fine as a Carry and compared to Eternity she's great.
              </li>
              <li>
                <strong>Ms. Newbabel</strong> from A+ to A in Overall. She's a
                Reality DMG Tank which is giga niche and she's only good in a
                single Raid.
              </li>
            </ul>
            <p>
              Global Tier list has been updated for 1.5 patch. List of changes:
            </p>
            <ul>
              <li>
                <strong>Ms Newbabel</strong> from A+ to A in Mane's. The
                [Counter] effect is not useful besides Marsh so she kinda mid.
              </li>
              <li>
                <strong>Centurion</strong> from A to A+ in 1.5 Mane's. She can
                SSS the Robot Raid but is doodoo in the other Raids.
              </li>
              <li>
                <strong>Voyager</strong> from S to A+ in 1.5 Mane's. Marsh isn't
                present so [Counter] sucks but Gorgon is back and she's good
                there.
              </li>
              <li>
                <strong>Balloon Party</strong> from A+ to A in 1.5 Mane's.
                [Counter] sucks and HP Lost based healing isn't great for
                Endless Mode Raids.
              </li>
              <li>
                <strong>Druvis III</strong> from A+ to A in 1.5 Mane's. She's a
                decent Support in Robot but beyond that she isn't used.
              </li>
              <li>
                <strong>Kaalaa Baunaa</strong> from S to A+ in Mane's. She's
                fine enough as is as a base character but mid in Gorgon and bad
                in Exhibition.
              </li>
              <li>
                <strong>Spathodea</strong> ratings added; A+ in 1.5 Standard and
                S in 1.5 Mane's.
              </li>
              <li>
                <strong>Desert Flannel</strong> ratings added; B in 1.5 Standard
                and A in 1.5 Mane's.
              </li>
              <li>
                <strong>Ezra Theodore</strong> ratings added; A+ in 1.5 Standard
                and S in 1.5 Mane's.
              </li>
              <li>
                <strong>Ulu</strong> ratings added; C in 1.5 Standard and B in
                1.5 Mane's.
              </li>
            </ul>
            <h6>02.04.2024</h6>
            <p>Tier list has been fully revamped.</p>
            <h6>10.03.2024</h6>
            <p>
              Updated the CN tier list to 1.7 patch and updated the Overall tier
              list. Some comments about the changes:
            </p>
            <ul>
              <li>
                With the introduction of Isolde, <strong>Spathodea</strong> now
                actually has a good teammate as opposed to being relegated to be
                paired with Ult to provide [Burn] for her. Due to her
                hard-hitting kit especially under the effects of [Burn] we think
                she deserves to be raised out of A tier for it,
              </li>
              <li>
                <strong>Centurion</strong> used to be the overall
                jack-of-all-trades Carry but now that specific teams are
                becoming more important Centurion is slowly falling out of meta
                for simply not being able to keep up with other units in terms
                of burst damage, so we decided to drop her to A in Overall tier
                list,
              </li>
              <li>
                <strong>Voyager</strong> really needs [Counter] to be effective
                and the trickiness of getting her Skill 1 to rank 2 in order to
                apply [Confusion] is just really annoying. Needs to be somewhat
                AP greedy or be played very well in order to time her other
                debuffs and generally just... doesn't feel like she does much
                compared to the other Supports in recent times, so we dropped
                her to B,
              </li>
              <li>
                <strong>Sonetto</strong> been A-tier since the very beginning
                but we just can't justify putting her in the same tier as
                Support powerhouses like An-an and Pickles especially because
                she's An-an's budget version. Still good, but with the insanely
                good Supports from 1.4 and onwards she just doesn't stack up as
                well, still fine though,
              </li>
              <li>
                <strong>Getian's</strong> Domain Expansion is fixed and now
                properly stacks with other +DMG Dealt buffs like AAL and
                Pickles, so we're raising his rating,
              </li>
              <li>
                With introduction of Isolde, <strong>37</strong> just kinda
                falls flat as a Support for the Jiu Niangzi National team. Her
                supportive capabilities just don't match up to what is expected
                of a Support and she was only a part of that team due to the
                unique interaction between Jiu's Channel and [Eureka]. Not only
                does Isolde synergize with Jiu's constantly attacks she is also
                a Reality/Crit debuffer and generally outputs a lot of damage
                for a unit that's categorically a Support unlike 37 who is
                'officially' a Carry. So we're dropping 37 to A tier Overall.
              </li>
            </ul>
            <h6>29.02.2024</h6>
            <p>Updated the Global tier list to 1.5 patch.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="tier-list-header">
        <p className="title">
          Tier List{' '}
          <span>
            ({sortOption === 'standard_cn' && 'Standard - 2.1'}
            {sortOption === 'standard_global' && 'Standard - 1.9'}
            {sortOption === 'mane_cn' && 'Raid - 2.1'}
            {sortOption === 'mane_global' && 'Raid - 1.9'}
            {sortOption === 'overall' && 'Overall'})
          </span>
        </p>
        <p className="sub-title">
          You're currently viewing the{' '}
          <strong>
            {sortOption === 'standard_cn' && 'Standard (2.1)'}
            {sortOption === 'standard_global' && 'Standard (1.9)'}
            {sortOption === 'mane_cn' && 'Raid (2.1)'}
            {sortOption === 'mane_global' && 'Raid (1.9)'}
            {sortOption === 'overall' && 'Overall'}
          </strong>{' '}
          tier list. It shows{' '}
          {(sortOption === 'standard_global' || sortOption === 'standard_cn') &&
            'how the character performs within their role in Event, UTTU, and Limbo.'}
          {(sortOption === 'mane_global' || sortOption === 'mane_cn') &&
            'how the character performs within their role in the content available in Raids (aka Mane Bulletin).'}
          {sortOption === 'overall' &&
            'how the character performs ignoring the patch specific content - the rating showcases the overall character strength.'}{' '}
          Use the switcher below to view a different tier list.
        </p>
      </div>
      {altRegion ? (
        <div className="tier-list-switcher">
          <div
            className={`option overall ${
              sortOption === 'overall' && 'selected'
            }`}
            onClick={() => setSortOption('overall')}
          >
            <p>Overall</p>
          </div>
          <div
            className={`option standard ${
              sortOption === 'standard_cn' && 'selected'
            }`}
            onClick={() => setSortOption('standard_cn')}
          >
            <p>Standard (2.1)</p>
          </div>
          <div
            className={`option raid ${sortOption === 'mane_cn' && 'selected'}`}
            onClick={() => setSortOption('mane_cn')}
          >
            <p>Raid (2.1)</p>
          </div>
        </div>
      ) : (
        <div className="tier-list-switcher">
          <div
            className={`option overall ${
              sortOption === 'overall' && 'selected'
            }`}
            onClick={() => setSortOption('overall')}
          >
            <p>Overall</p>
          </div>
          <div
            className={`option standard ${
              sortOption === 'standard_global' && 'selected'
            }`}
            onClick={() => setSortOption('standard_global')}
          >
            <p>Standard (1.9)</p>
          </div>
          <div
            className={`option raid ${
              sortOption === 'mane_global' && 'selected'
            }`}
            onClick={() => setSortOption('mane_global')}
          >
            <p>Raid (1.9)</p>
          </div>
        </div>
      )}
      <div className="region-switcher">
        <div className="custom-switch">
          <Switch
            checked={altRegion}
            onChange={() => setAltRegion(!altRegion)}
            onColor="#009EEC"
            offColor="#484950"
            className="switch"
            disabled={sortOption === 'overall'}
          />
          Switch to <strong>CN</strong> tier list (2.1)
        </div>
      </div>
      <div className="employees-filter-bar">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="custom-tier-list-reverse">
        <div className="custom-tier-header">
          <div className="tier-rating">
            <span>&nbsp;</span>
          </div>
          <div className="custom-tier-container">
            <div className="custom-header dps">Damage dealer</div>
            <div className="custom-header sub-dps">Sub Carry</div>
            <div className="custom-header support">Support</div>
            <div className="custom-header survival">Survival</div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header meta">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Best In Role{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s first">
          <div className="tier-rating s-plus">
            <span>S+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp.tierListCategory === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp.tierListCategory === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp.tierListCategory === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 10)
                .filter((emp) => emp.tierListCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header top">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Top Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-s">
          <div className="tier-rating s">
            <span>S</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp.tierListCategory === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp.tierListCategory === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp.tierListCategory === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 9)
                .filter((emp) => emp.tierListCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className="custom-tier tier-a-plus">
          <div className="tier-rating a-plus">
            <span>A+</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp.tierListCategory === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp.tierListCategory === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp.tierListCategory === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 8)
                .filter((emp) => emp.tierListCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={`meta-line`}>
          <h5 className="meta-header alt">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Common
            Replacements <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-a">
          <div className="tier-rating a">
            <span>A</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp.tierListCategory === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp.tierListCategory === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp.tierListCategory === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 7)
                .filter((emp) => emp.tierListCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-b">
          <div className="tier-rating b">
            <span>B</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp.tierListCategory === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp.tierListCategory === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp.tierListCategory === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 6)
                .filter((emp) => emp.tierListCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
        <div className={`meta-line`}>
          <h5 className="meta-header niche">
            <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche Picks{' '}
            <FontAwesomeIcon icon={faAnglesDown} width="18" />
          </h5>
        </div>
        <div className="custom-tier tier-c">
          <div className="tier-rating c">
            <span>C</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp.tierListCategory === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp.tierListCategory === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp.tierListCategory === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 5)
                .filter((emp) => emp.tierListCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="custom-tier tier-e">
          <div className="tier-rating d">
            <span>D</span>
          </div>
          <div className="custom-tier-container">
            <div className="category-mobile dps">Damage dealer</div>
            <div className="employees-container dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp.tierListCategory === 'Damage')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile sub-dps">Sub Carry</div>
            <div className="employees-container sub-dps">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp.tierListCategory === 'Sub DPS')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile support">Support</div>
            <div className="employees-container support">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp.tierListCategory === 'Support')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
            <div className="category-mobile survival">Survival</div>
            <div className="employees-container survival">
              {visibleCharacters
                .filter((emp) => emp.ratingsNew[sortOption] === 4)
                .filter((emp) => emp.tierListCategory === 'Survival')
                .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                .map((emp, index) => {
                  return (
                    <span key={index}>
                      <Card className="avatar-card">
                        <Reverse1999Character
                          slug={emp.slug}
                          mode="card"
                          showLabel
                          showIcon
                          enablePopover
                          tags={emp.tierListTags}
                          currentTier={sortOption}
                          showTierListChange
                        />
                      </Card>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default REVTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Reverse: 1999 | Prydwen Institute"
    description="A tier list for Reverse: 1999 that rates all characters based on their Global, CN and Overall performance."
    game="reverse"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulReverseCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        tierListCategory
        tierListTags
        rarity
        afflatus
        damageType
        tags
        ratingsNew {
          overall
          standard_global
          standard_cn
          mane_global
          mane_cn
        }
        tierComment {
          tierComment
        }
        releasePatch
        availableInGlobal
        isReviewPending
        isNew
      }
    }
  }
`;
